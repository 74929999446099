/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
  transition: transform 300ms ease-in-out;
  transition-delay: 100ms;
  transform: scale(0);
  /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open{
  transform: scale(1);
  /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close{
  transform: scale(0);
  /* transform: translateY(-150%); */
}

.footer-logo-off svg {
  width: 50vw;
  height: auto;
}

@media (min-width: 600px) {
  .footer-logo-off svg {
    width: 10vw;
    height: auto;
  }
}